<template>
  <div class="qrcode-edit-page">
    <v-qrcode-management />
  </div>
</template>

<script>
import VQRCodeManagement from "../../components/qrcodes/VQRCodeManagement";

export default {
  name: "QRCodeCreate",

  components: {
    "v-qrcode-management": VQRCodeManagement,
  },
};
</script>